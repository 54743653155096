/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $( "#featured .feature" ).hover(
          function() {
            $(this).find('img').attr('src', $(this).find('img').attr('data-hover'));
          }, function() {
            $(this).find('img').attr('src', $(this).find('img').attr('data-original'));
          }
        );
        
        $('.grayscale').toggleClass('grayscale-off');

        $('.navbar-collapse').on('shown.bs.collapse', function() {
          $('.navbar-brand').addClass('brand2'); 
          $('body').css('overflow', 'hidden'); 
        });

        $('.navbar-collapse').on('hidden.bs.collapse', function() {
          $('.navbar-brand').removeClass('brand2');
          $('body').css('overflow', 'auto');
        });

        var nScrollStart = 0;
        $(document).scroll(function() {
          nScrollStart = $(this).scrollTop();
          if (nScrollStart >= 1) {
            $(".navbar-default").addClass("fixed");
            $("body").addClass("fixed");
          } else {
            $(".navbar-default").removeClass("fixed");
            $("body").removeClass("fixed");
          }
        });

        function center_map( map ) {
          var bounds = new google.maps.LatLngBounds(); 
          $.each( map.markers, function( i, marker ){ 
            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
            bounds.extend( latlng );
          });
          if( map.markers.length === 1 ) {
              map.setCenter( bounds.getCenter() );
              map.setZoom( 16 );
          } else {
            map.fitBounds( bounds );
          }
        }

        function add_marker( $marker, map ) {
          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
          var marker = new google.maps.Marker({
            position  : latlng,
            map     : map
          });
          map.markers.push( marker );
          if( $marker.html() ) {
            var infowindow = new google.maps.InfoWindow({
              content   : $marker.html()
            });
            google.maps.event.addListener(marker, 'click', function() {
              infowindow.open( map, marker );
            });
          }
        }

        function new_map( $el ) { 
          var $markers = $el.find('.marker'); 
          var args = {
            zoom    : 16,
            center    : new google.maps.LatLng(0, 0),
            mapTypeId : google.maps.MapTypeId.ROADMAP,
            scrollwheel: false
          };        
          var map = new google.maps.Map( $el[0], args);
          map.markers = [];
          $markers.each(function(){   
              add_marker( $(this), map );   
          }); 
          center_map( map );  
          return map;
        }

        var map = null;
        $(document).ready(function(){
          $('#googleMap').each(function(){
            map = new_map( $(this) );
          });
        }); 

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
